
import {
  defineComponent,
  reactive,
  toRefs,
  onMounted,
  computed,
  ref,
} from "vue";
import { Api } from "@/assets/Api";
import { ElMessage, ElMessageBox } from "element-plus";
import store from "@/store";
import moment from "moment";
import OrderLog from "./OrderLog.vue";
import excel from "avue-plugin-excel";

export default defineComponent({
  name: "RegisterOrder",
  components: { OrderLog },
  setup() {
    let data = reactive<any>({
      statusOptions: [
        {
          value: "",
          label: "全部",
        },
        {
          value: "0",
          label: "未确认",
        },
        {
          value: "1",
          label: "院方受理",
        },
        {
          value: "2",
          label: "院方废弃",
        },
        {
          value: "3",
          label: "完成提醒回访",
        },

        {
          value: "4",
          label: "完成废弃回访",
        },
        {
          value: "5",
          label: "完成订单",
        },
        {
          value: "6",
          label: "违约",
        },
        {
          value: "7",
          label: "停诊",
        },
        {
          value: "8",
          label: "用户取消",
        },
      ],

      search: {
        status: "",
        hospitalOptions: [],
        hospitalProps: { expandTrigger: "hover" },
        jzdateShortcuts: [
          {
            text: "前后各一周",
            value: (() => {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              end.setTime(end.getTime() + 3600 * 1000 * 24 * 7);
              return [start, end];
            })(),
          },
          {
            text: "今天",
            value: (() => {
              const end = new Date();
              const start = new Date();
              return [start, end];
            })(),
          },
          {
            text: "明天",
            value: (() => {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() + 3600 * 1000 * 24);
              end.setTime(end.getTime() + 3600 * 1000 * 24);
              return [start, end];
            })(),
          },
          {
            text: "后三十天",
            value: (() => {
              const end = new Date();
              const start = new Date();
              end.setTime(end.getTime() + 3600 * 1000 * 24 * 30);
              return [start, end];
            })(),
          },
        ],
        jzdate: [
          moment(new Date().toISOString())
            .subtract(7, "days")
            .format("YYYY-MM-DD"),
          moment(new Date().toISOString()).add(7, "days").format("YYYY-MM-DD"),
        ],
        ghdatetime: "",
        userinfo: "",
        orderid: "",
        hospitalid: "",
        doctorname: "",
      },
      init: false,
      data: {},
      option: {},
      page: {
        total: 0,
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 20],
        layout: "total, sizes, prev, pager, next",
      },
      query: {
        limit: 10,
        page: 1,
        where: {},
        sort: "-id",
      },
      role: "",
      logDialogVisible: false,
    });

    const downExcel = () => {
      if (
        !(
          data.search.jzdate &&
          moment(data.search.jzdate[0]).format("YYYY-MM-DD") ==
            moment(data.search.jzdate[1]).format("YYYY-MM-DD")
        )
      ) {
        ElMessage.warning({
          showClose: true,
          duration: 10000,
          message: "请选择就诊日期，一次只能下载一天的订单",
        });
        return;
      }
      if (!(data.role == "Hospital")) {
        if (!data.search.hospitalid) {
          ElMessage.warning({
            showClose: true,
            duration: 10000,
            message: "请选择一家医院",
          });
          return;
        }
      }
      console.log(data.search.jzdate);
      const where: any = {};
      if (data.search.ghdatetime) {
        where.ghdatetime =
          "" + moment(data.search.ghdatetime).format("YYYY-MM-DD");
      }
      if (data.search.jzdate) {
        where.jzdates = "" + moment(data.search.jzdate[0]).format("YYYY-MM-DD");
        where.jzdatee = "" + moment(data.search.jzdate[1]).format("YYYY-MM-DD");
      }
      if (data.search.userinfo) {
        where.userinfo = data.search.userinfo;
      }
      if (data.search.orderid) {
        where.orderid = data.search.orderid;
      }
      if (data.search.hospitalid) {
        where.hospitalid = data.search.hospitalid[2];
      }
      if (data.search.doctorname) {
        where.doctorname = data.search.doctorname;
      }
      if (data.search.status) {
        where.status = data.search.status;
      }
      const query = {
        where: where,
        limit: 10,
        page: 1,
        sort: "-id",
      };
      Api.Register.DownloadRegList(query).then((res: any) => {
        console.log(res);
        if (res.Code == 0) {
          excel.excel({
            title: moment().format("YYYYMMDDHHmmss"),
            columns: res.Data.column,
            data: res.Data.data,
          });
        } else {
          ElMessage.warning({
            showClose: true,
            duration: 10000,
            message: res.ResultMsg,
          });
        }
      });
    };

    const GetTagType = computed(() => (status: number) => {
      switch (status) {
        case 0:
        case 1:
        case 3:
        case 5:
          return "success";
        case 2:
        case 4:
        case 8:
          return "warning";
        case 6:
        case 7:
          return "danger";
        default:
          return "info";
      }
    });

    const searchReset = () => {
      console.log("searchReset");

      data.search.jzdate = [
        moment(new Date().toISOString())
          .subtract(7, "days")
          .format("YYYY-MM-DD"),
        moment(new Date().toISOString()).add(7, "days").format("YYYY-MM-DD"),
      ];
      data.search.ghdatetime = "";
      data.search.userinfo = "";
      data.search.orderid = "";
      data.search.hospitalid = "";
      data.search.status = "";
    };

    const searchChange = (params: any, done: any) => {
      if (data.search.ghdatetime) {
        params.ghdatetime =
          "" + moment(data.search.ghdatetime).format("YYYY-MM-DD");
      }
      if (data.search.jzdate) {
        params.jzdates =
          "" + moment(data.search.jzdate[0]).format("YYYY-MM-DD");
        params.jzdatee =
          "" + moment(data.search.jzdate[1]).format("YYYY-MM-DD");
      }
      if (data.search.userinfo) {
        params.userinfo = data.search.userinfo;
      }
      if (data.search.orderid) {
        params.orderid = data.search.orderid;
      }
      if (data.search.hospitalid) {
        params.hospitalid = data.search.hospitalid[2];
      }
      if (data.search.doctorname) {
        params.doctorname = data.search.doctorname;
      }
      if (data.search.status) {
        params.status = data.search.status;
      }
      data.query.where = params;
      data.page.currentPage = 1;
      data.query.page = 1;
      data.query.limit = data.page.pageSize;
      getList();
      done();
    };

    const changePage = ({ pageSize, currentPage }: any) => {
      // if (Object.keys(data.query.where).length === 0) {
      //   return;
      // }
      data.query.page = currentPage;
      data.query.limit = pageSize;
      getList();
    };

    const getList = () => {
      console.log(data.query);
      Api.Register.GetRegList(data.query).then((res: any) => {
        console.log(res);
        data.data = {};
        if (res.Code == 0) {
          data.data = res.Data;
          data.page.total = res.Data.total;
          data.page.currentPage = res.Data.page;
        } else {
          ElMessage.warning({
            showClose: true,
            duration: 10000,
            message: res.ResultMsg,
          });
        }
      });
    };

    const rowUpdate = (form: any, index: any, done: any, loading: any) => {
      var aa = {
        ID: form.id.toString(),
        PatientIdCard: form.sfz ?? "",
        PatientName: form.username ?? "",
        PatientMobile: form.tel ?? "",
        PatientMediCardNbr: form.jzkh ?? "",
        Addr: form.address ?? "",
        remark: form.remark ?? "",
      };

      Api.Api2.PlanEditOrder(aa).then((res: any) => {
        if (res.code == 0) {
          ElMessage.success(res.data);
          getList();
          done();
        } else {
          loading();
          ElMessage.warning({
            showClose: true,
            duration: 10000,
            message: res.data,
          });
        }
      });
    };

    const getOption = () => {
      Api.Register.GetRegListOption().then((res: any) => {
        console.log(res);
        data.option = res;
        data.init = true;
      });
    };

    const goDoOrder = (Value: any) => {
      doOrder(Value.row, Value.dotype, "");
    };

    const crud = ref();
    const doOrder = (row: any, dotype: any, index: any) => {
      if (dotype == -1) {
        switch (data.role) {
          case "Hospital":
            if (row.status == 1 || row.status == 3) return "用户违约";
            else SetLog(row);
            break;
          default:
            if (row.status == 0 || row.status == 1 || row.status == 3)
              SetRegListStatus(row, 8);
            else SetLog(row);
            break;
        }
      } else {
        switch (dotype) {
          case 9:
            SetLog(row);
            break;
          case 10:
            console.log(row, index);

            crud.value.rowEdit(row, index);
            // this.order = row;
            // this.orderDialogVisible = true;
            break;
          case 11:
          case 12:
            PlanOrderSms(row);
            break;
          default:
            SetRegListStatus(row, dotype);
            break;
        }
      }
    };

    const SetRegListStatus = (row: any, status: any) => {
      let vstatus = "";
      switch (status) {
        case 4:
          vstatus = "停诊";
          break;
        case 6:
          vstatus = "用户违约";
          break;
        case 7:
          vstatus = "停诊";
          break;
        case 8:
          vstatus = "用户取消";
          break;
      }
      ElMessageBox.prompt(
        `请确认（${vstatus}${row.username}用户，${row.doctorname}号源）并输入备注`,
        `${vstatus}确认`,
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        }
      )
        .then(({ value }) => {
          const action = {
            status: status,
            remark: value ?? "",
          };
          Api.Register.SetRegListStatus(row.id, action).then((res: any) => {
            if (res.Code == 0) {
              ElMessage.success("保存成功");
              getList();
            } else {
              ElMessage.warning({
                showClose: true,
                duration: 10000,
                message: res.ResultMsg,
              });
            }
          });
        })
        .catch(() => {
          ElMessage({
            type: "info",
            message: "Input canceled",
          });
        });
    };

    const PlanOrderSms = (row: any) => {
      Api.Api2.PlanOrderSms({ ID: row.id }).then((res: any) => {
        if (res.code == 0) {
          ElMessage.success(res.data);
        } else {
          ElMessage.warning({
            showClose: true,
            duration: 10000,
            message: res.data,
          });
        }
      });
    };

    const OrderValue = (row: any, dotype: any, index: any) => {
      //if (dotype == 9) crud.value.rowEdit(row, index);
      return {
        row: row,
        dotype: dotype,
        index: index,
      };
    };

    const menuButtonTitle = computed(() => (status: number) => {
      switch (data.role) {
        case "Hospital":
          if (status == 1 || status == 3) return "用户违约";
          else return "查看记录";
        default:
          if (status == 0 || status == 1 || status == 3) return "用户取消";
          else return "查看记录";
      }
    });

    const menuButtonDropdown = computed(() => (status: number) => {
      const dropdown = [];
      switch (data.role) {
        case "Hospital":
          if (status == 0) {
            dropdown.push({ title: "院方受理", do: 1 });
            dropdown.push({ title: "院方取消", do: 2 });
          }
          dropdown.push({ title: "查看记录", do: 9 });
          break;
        case "gh":
          if (status == 0 || status == 1 || status == 3) {
            dropdown.push({ title: "重新发送初始短信", do: 11 });
            dropdown.push({ title: "修改患者信息", do: 10 });
          }
          dropdown.push({ title: "查看记录", do: 9 });
          break;
        default:
          if (status == 0) {
            dropdown.push({ title: "院方受理", do: 1 });
            dropdown.push({ title: "院方取消", do: 2 });
          } else if (status == 1) {
            dropdown.push({ title: "完成提醒回访", do: 3 });
          } else if (status == 2) {
            dropdown.push({ title: "完成取消回访", do: 4 });
          } else if (status == 3) {
            dropdown.push({ title: "完成订单", do: 5 });
            dropdown.push({ title: "用户违约", do: 6 });
          }
          if (status == 0 || status == 1 || status == 3) {
            dropdown.push({ title: "停诊", do: 7 });
            dropdown.push({ title: "重新发送初始短信", do: 11 });
            dropdown.push({ title: "修改患者信息", do: 10 });
          }
          if (status == 7) {
            dropdown.push({ title: "重新发送停诊短信", do: 12 });
          }
          dropdown.push({ title: "查看记录", do: 9 });
          break;
      }
      return dropdown;
    });

    const OrderLogRef = ref<InstanceType<typeof OrderLog>>();
    const SetLog = (row: any) => {
      data.logDialogVisible = true;
      OrderLogRef.value?.searchChange(row.id);
    };

    onMounted(() => {
      const allHospital: string = store.getters.allHospital;
      if (allHospital) {
        const jsonHospital = JSON.parse(allHospital);
        data.search.hospitalOptions = jsonHospital;
      }
      data.role = store.getters.role;
      getOption();
      console.log(crud);
    });

    return {
      ...toRefs(data),
      searchChange,
      changePage,
      GetTagType,
      searchReset,
      goDoOrder,
      doOrder,
      OrderValue,
      SetLog,
      menuButtonTitle,
      menuButtonDropdown,
      OrderLogRef,
      downExcel,
      PlanOrderSms,
      rowUpdate,
      crud,
    };
  },
});
