
import { defineComponent, reactive, toRefs, computed, onMounted } from "vue";
import { Api } from "@/assets/Api";
import { ElMessage } from "element-plus";

export default defineComponent({
  name: "RegisterOrderLog",
  props: ["dialogFormVisible"],
  emits: ["close"],
  components: {},
  setup(props, { emit }) {
    let data = reactive<any>({
      init: false,
      data: {},
      option: {},
      page: {
        total: 0,
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 20],
        layout: "total, sizes, prev, pager, next",
      },
      query: {
        limit: 10,
        page: 1,
        where: {},
        sort: "-id",
      },
    });

    const dialogVisible = computed({
      get() {
        return props.dialogFormVisible;
      },
      set() {
        emit("close");
      },
    });

    const searchChange = (regid: number) => {
      console.log(regid);

      data.query.where = {
        log_regid: regid,
      };
      console.log(data);

      data.page.currentPage = 1;
      data.query.page = 1;
      data.query.limit = data.page.pageSize;
      getList();
    };

    const changePage = ({ pageSize, currentPage }: any) => {
      data.query.page = currentPage;
      data.query.limit = pageSize;
      getList();
    };

    const getList = () => {
      console.log(data.query);
      Api.Register.GetRegLogList(data.query).then((res: any) => {
        console.log(res);
        data.data = {};
        if (res.Code == 0) {
          data.data = res.Data;
          data.page.total = res.Data.total;
          data.page.currentPage = res.Data.page;
        } else {
          ElMessage.warning({
            showClose: true,
            duration: 10000,
            message: res.ResultMsg,
          });
        }
      });
    };

    const getOption = () => {
      Api.Register.GetRegLogListOption().then((res: any) => {
        console.log(res);
        data.option = res;
        data.init = true;
      });
    };

    onMounted(() => {
      getOption();
    });

    return {
      ...toRefs(data),
      ...toRefs(props),
      dialogVisible,
      searchChange,
      changePage,
    };
  },
});
